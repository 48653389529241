<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        :to="createPath"
        variant="primary"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      :resource="resource"
      :columns="columns"
      :filters="filters"
      :details-component="ContentTranslationDetails"
    />
  </div>
</template>

<script>
import { VxTable, VxCellButtons, VxCellDate, VxCellTooltip, VxCellDetailsToggler } from '@/components/table'
import { contents, passDataToResource, useResource } from '@/services/resources'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'
import { filterTypes, sortOrders } from '@/services/table'
import ContentTranslationDetails from '@/views/texts/ContentTranslationDetails'

export default {
  name: 'ContentTranslations',
  components: {
    VxTable,
    VxCellTooltip,
    VxPageTitleActions,
    VxButton,
    VxCellButtons,
    VxCellDate,
    VxCellDetailsToggler,
    ContentTranslationDetails
  },
  setup () {
    const resource = passDataToResource(contents.getAll, {
      requestParams: {
        params: { include: 'translations' }
      }
    })

    const createPath = path.createContent

    const { can: canCreate } = useResource(contents.create)
    const { can: canUpdate } = useResource(contents.update)

    const columns = [
      {
        key: 'details',
        label: '',
        component: VxCellDetailsToggler,
        tdClass: ['p-0', 'width-50']
      },
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'title',
        component: VxCellTooltip,
        tdAttr: { maxWidthClass: 'max-width-300' }
      },
      { key: 'slug' },
      { key: 'type' },
      {
        key: 'created_at',
        component: VxCellDate
      },
      {
        key: 'updated_at',
        component: VxCellDate
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const actionButtons = ({ id }) => {
      const editButton = {
        ...buttons.edit,
        can: canUpdate,
        to: buildRoute(path.updateContent, { params: { id } })
      }

      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(contents.delete, {
          requestParams: { urlParams: { id } }
        })
      }

      return [editButton, deleteButton]
    }

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'title',
        type: filterTypes.text
      },
      {
        key: 'slug',
        type: filterTypes.text
      },
      {
        key: 'type',
        type: filterTypes.serverSelect,
        resource: contents.getTypes
      }
    ]

    return {
      resource,
      columns,
      filters,
      ContentTranslationDetails,

      canCreate,
      createPath
    }
  }
}
</script>
