<template>
  <vx-table-simple
    :columns="columns"
    :items="items"
  />
</template>

<script>
import VxTableSimple from '@/components/table/vx-table-simple/VxTableSimple'
import { VxCellDate, VxCellMoney, VxCellTooltip } from '@/components/table'

export default {
  name: 'ContentTranslationDetails',
  components: {
    VxTableSimple,
    VxCellMoney,
    VxCellDate,
    VxCellTooltip
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { item: { translations } } }) {
    const columns = [
      { key: 'id' },
      {
        key: 'lang',
        label: 'Language'
      },
      {
        key: 'text',
        component: VxCellTooltip,
        tdAttr: { maxWidthClass: 'max-width-400' }
      },
      {
        key: 'created_at',
        component: VxCellDate
      },
      {
        key: 'updated_at',
        component: VxCellDate
      }
    ]

    return {
      items: translations,
      columns
    }
  }
}
</script>
